<template>
    <div id="app">
        <hox-loading-bar />
        <Layout class="campaign-layout">
            <template v-if="isAuthorizedContentVisible">
                <campaign-header />
            </template>
            <Sider
                :collapsible="true"
                :value="!isSidebarOpen"
                :collapsed-width="sidebarWidthCollapsed"
                :width="sidebarWidthOpen"
                :hide-trigger="true"
            >
                <portal-target name="main-sidebar" slim></portal-target>
            </Sider>
            <Layout class="campaign-content">
                <Content class="main-content">
                    <router-view :key="$route.fullPath" class="hox-full-width router-view" />
                </Content>
            </Layout>
        </Layout>
    </div>
</template>

<script>
import HoxLoadingBar from "@/HoxLoadingBar";
import CampaignHeader from "@/CampaignHeader";
import themeMixin from "@/mixins/themeMixin";

export default {
    name: "ThemeCampaignDark",
    components: {
        CampaignHeader,
        HoxLoadingBar
    },
    mixins: [themeMixin],

    data() {
        return {
            sidebarWidth: 250
        };
    },

    computed: {
        showCampaignNav() {
            return !(
                this.$store.state.route &&
                this.$store.state.route.meta &&
                this.$store.state.route.meta.hideCampaignNav
            );
        }
    }
};
</script>

<style lang="scss">
@use "sass:color";
@import "../sass/variables";

.theme-campaign-dark {
    .campaign-header {
        background-color: $cmp-dark-bg-color;
    }

    .main-content,
    .ivu-collapse {
        color: $cmp-dark-font-color;

        &.main-content {
            background-color: $campaign-nav-bg;
        }
    }

    .ivu-collapse {
        background-color: $cmp-dark-secondary-bg-color;

        &-content {
            background-color: $cmp-dark-secondary-bg-color;
            color: $cmp-dark-font-color;
        }
    }

    .ivu-collapse > .ivu-collapse-item {
        .ivu-collapse-header {
            color: $cmp-dark-font-color;
            border-bottom: 1px solid $cmp-dark-border-color;
            background-color: $cmp-dark-secondary-bg-color;
        }

        &.ivu-collapse-item-active {
            border-bottom: 1px solid $cmp-dark-border-color;

            .ivu-collapse-header {
                background-color: $cmp-dark-secondary-bg-color;
            }
        }
    }

    .ivu-radio {
        margin-right: 8px;

        .ivu-radio-inner {
            border-color: $cmp-dark-tertiary-bg-color;
            background-color: $cmp-dark-secondary-bg-color;
        }

        &-checked {
            .ivu-radio-inner:after {
                background-color: $cmp-dark-active;
                width: 6px;
                height: 6px;
                top: 3px;
                left: 3px;
            }
        }
    }

    .ivu-layout-sider {
        background-color: $cmp-dark-bg-color;
    }

    .ivu-input {
        color: $cmp-dark-font-color;
        background-color: $cmp-dark-input-bg-color;
        border-color: $darktheme20;

        &:hover,
        &:focus,
        &:active {
            border-color: $darktheme20;
        }
    }

    .ivu-select.hox-select-dark {
        .ivu-select-selection {
            color: $cmp-dark-font-color;
            background-color: $cmp-dark-input-bg-color;
            border-color: $darktheme20;
        }

        .ivu-select-placeholder {
            color: $grey5;
        }

        .ivu-select-dropdown {
            margin-top: 1px;
            padding: 0;
            height: auto;
            background: $cmp-dark-btn-bg-color;

            &:hover {
                background: $cmp-dark-btn-bg-color;
            }

            .ivu-select-item {
                color: $cmp-dark-font-color;
                border-bottom: 1px solid $cmp-dark-border-color;
                padding: 10px;

                &:last-child {
                    border-bottom: none;
                }

                &.ivu-select-item-selected {
                    color: $cmp-dark-active;
                }

                &.ivu-select-item-focus {
                    background: $cmp-dark-btn-bg-color;
                }

                &:hover {
                    background: color.adjust($cmp-dark-btn-bg-color, $lightness: 10%);
                }
            }
        }
    }

    .ivu-poptip {
        &-inner {
            background-color: $cmp-dark-tertiary-bg-color;
        }
    }

    &[x-placement^="bottom"] .ivu-poptip-arrow:after,
    [x-placement^="bottom"] .ivu-poptip-arrow:after {
        border-bottom-color: $cmp-dark-tertiary-bg-color;
    }

    &[x-placement^="right"] .ivu-poptip-arrow:after,
    [x-placement^="right"] .ivu-poptip-arrow:after {
        border-right-color: $cmp-dark-tertiary-bg-color;
    }

    .ivu-slider {
        &-bar {
            background-color: $primary-color;
        }

        &-wrap {
            background-color: $darktheme20;
        }

        &-button {
            background-color: $darktheme20;
            border: 4px solid $primary-color;
        }
    }

    .campaign-banner {
        border: 1px solid $darktheme40;
        box-sizing: content-box;

        &-footer {
            background: $cmp-dark-secondary-bg-color;
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    .vb {
        .vb-dragger {
            background: $darktheme20;

            &:hover {
                background: $darktheme0;
            }
        }

        &.vb-scrolling-phantom .vb-dragger,
        &.vb-dragging .vb-dragger,
        &.vb-dragging-phantom .vb-dragger {
            background: $darktheme20;
        }
    }

    .dark-scrollbar {
        &::-webkit-scrollbar {
            width: 11px;
            height: 11px;
        }

        &::-webkit-scrollbar-track {
            background-color: $darktheme100;
        }

        &::-webkit-scrollbar-corner {
            background-color: $darktheme100;
        }

        &::-webkit-scrollbar-thumb {
            border: 2px solid transparent;
            background-clip: padding-box;
            border-radius: 7px;
            background-color: $darktheme0;

            &:hover {
                background-color: $darktheme20;
            }
        }

        &--modal::-webkit-scrollbar-track {
            background-color: $cmp-dark-bg-color;
        }
    }
}
</style>
