import * as cheerio from "cheerio";
const config = Object.seal({
    sizmek_sv: {
        head: {
            add: [
                "<script type=\"text/javascript\" src=\"http://ds.serving-sys.com/BurstingScript/adKit/adkit.js\"></script>",
                "<script>EBModulesToLoad = ['Video','EBAPI','EBCMD'];</script>",
            ],
        },
    },
    sizmek: {
        head: {
            add: [
                "<script>EBModulesToLoad = ['Comm','Video','EBAPI','EBCMD'];</script>",
                "<script type=\"text/javascript\" src=\"https://secure-ds.serving-sys.com/BurstingScript/EBLoader.js\"></script>",
            ],
        },
        /*
         * // don't think this is used anywhere, but leaving it until we know for sure
         * "head-expandable": {
         *  add: [
         *      "<script>EBModulesToLoad = ['Comm','Video','EBAPI','EBCMD'];</script>",
         *      "<script type=\"text/javascript\" src=\"https://secure-ds.serving-sys.com/BurstingScript/adKit/adkit.js\"></script>",
         *      "<script>if( 0 )EB.initExpansionParams(0, 0, @width, @height);</script>",
         *      "<script type=\"text/javascript\" src=\"js/localPreview.js\"></script>",
         *  ],
         * },
         */
    },
    doubleclick: {
        head: {
            add: [
                "<script type=\"text/javascript\" src=\"https://s0.2mdn.net/ads/studio/Enabler.js\"></script>",
            ],
            replace: [{
                    selector: "meta[name=\"ad.size\"]",
                    value: "<meta name=\"ad.size\" content=\"width=@width,height=@height\">",
                }],
        },
    },
    doubleclick_sv: {
        head: {
            add: [
                "<script src=\"https://s0.2mdn.net/ads/studio/Enabler.js\"></script>",
                "<script type=\"text/javascript\">Enabler.setProfileId(@dcProfileId);var dcFeedName = \"@dcFeedName\"</script>",
            ],
            replace: [{
                    selector: "meta[name=\"ad.size\"]",
                    value: "<meta name=\"ad.size\" content=\"width=@width,height=@height\">",
                }],
        },
    },
    generic: {
        head: {
            add: ["<script type=\"text/javascript\">var clickTag = \"http://www.google.com\";</script>"],
            replace: [
                {
                    selector: "script[src*=\"gsap\"]",
                    value: "<script type=\"text/javascript\" src=\"https://cdn.jsdelivr.net/npm/gsap@3.5.1/dist/gsap.min.js\"></script>",
                },
            ],
        },
        custom: ($) => {
            const bodyElement = $("body");
            const selector = "a[href=\"javascript:window.open(window.clickTag)\"]";
            const anchorTag = "<a style=\"all:revert\" href=\"javascript:window.open(window.clickTag)\"></a>";
            const element = bodyElement.find(selector);
            if (element.length) {
                const children = element.children();
                element.replaceWith(anchorTag);
                // Find the newly inserted element and append the children
                bodyElement.find(selector).append(children);
            }
            else {
                const tag = $(selector);
                if (tag.length === 0) {
                    // Wrap all the content between the hoxton tag and the first script tag within this tag
                    const link = $(anchorTag);
                    $("body")
                        .children()
                        .filter(function filter() {
                        return $(this).is(":not(hoxton):not(script)");
                    })
                        .appendTo(link);
                    $("hoxton").after(link);
                }
            }
        },
    },
    opendc: {
        head: {
            add: ["<script src='https://creative-libraries.lemonpi.io/v1/lemonpi.js'></script>"],
        },
    },
    flashtalking: {
        head: {
            replace: [
                {
                    selector: "script[src*=\"gsap\"]",
                    value: "<script type=\"text/javascript\" src=\"https://cdn.flashtalking.com/frameworks/js/gsap/3.5.1/gsap.min.js\"></script>",
                },
            ],
        },
        body: {
            add: ["<script src=\"https://cdn.flashtalking.com/frameworks/js/api/2/10/html5API.js\"></script>"],
        },
    },
});
function replaceSize(value, adWidth, adHeight) {
    if (!adWidth || !adHeight) {
        return value;
    }
    return value.replace("@width", adWidth).replace("@height", adHeight);
}
function replaceDcProfile(value, dcProfileId, feedName) {
    if (!dcProfileId || !feedName) {
        return value;
    }
    return value.replace("@dcProfileId", dcProfileId).replace("@dcFeedName", feedName);
}
function parseLine(value, manifestObject, opts) {
    const parsedLine = replaceDcProfile(value, opts.dcProfileId ?? manifestObject.dcProfileId, opts.dcFeedName);
    return replaceSize(parsedLine, manifestObject.adSize?.width, manifestObject.adSize?.height);
}
export function getConfigForPlatform(platform, manifestObject, opts) {
    function addMapper(value) {
        return parseLine(value, manifestObject, opts);
    }
    const platformConfig = { ...config[platform] };
    if (platformConfig.body?.add) {
        platformConfig.body.add = platformConfig.body.add.map(addMapper);
    }
    if (platformConfig.head?.add) {
        platformConfig.head.add = platformConfig.head.add.map(addMapper);
    }
    if (platformConfig.head?.replace) {
        platformConfig.head.replace = platformConfig.head.replace.map(({ selector, value }) => ({
            selector,
            value: parseLine(value, manifestObject, opts),
        }));
    }
    return platformConfig;
}
