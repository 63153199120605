<template>
    <hox-notification-item
        :has-content="hasFiles"
        :show-clear-button="hasFailed || hasCompleted"
        :show-error-message="hasFailed"
        :show-abort-button="showAbortButton"
        :status="notification.status"
        :notification="notification"
        :is-job-finished="isJobFinished"
        @clear="removeNotification"
        @notificationDeleted="deleteCompletedNotification"
    >
        <template #title>
            <span v-if="notification.job.campaignDetails && notification.job.clientDetails">
                Client:
                <strong>{{ notification.job.clientDetails?.name }}</strong>
                <br />
                Campaign:
                <strong>{{ notification.job.campaignDetails?.name }}</strong>
            </span>
            {{ title }}
        </template>
        <template #subtitle>
            {{ statusText }}
        </template>
        <template #content>
            <template v-if="hasFiles">
                <p><strong>Download reports here:</strong></p>
                <ul>
                    <li
                        v-for="file in notification.files"
                        :key="file.url"
                        class="report-notification-item__list-item"
                        :title="file.label"
                    >
                        <a :href="file.url" target="_blank">
                            {{ file.label }}
                        </a>
                    </li>
                </ul>
            </template>
        </template>
        <template #errorMessage>
            <p>There was an unexpected error while generating your report.</p>
            <p>Please try again in a few moments.</p>
        </template>
    </hox-notification-item>
</template>

<script>
import { NotificationStatus } from "@/enums/notifications";
import { NotificationsAction } from "@/store/modules/notifications";

export default {
    props: {
        notification: {
            required: true,
            type: Object
        },

        showAbortButton: {
            type: Boolean
        },

        isJobFinished: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasCompleted() {
            return this.notification.status === NotificationStatus.Completed;
        },

        hasFailed() {
            return this.notification.status === NotificationStatus.Failed;
        },

        hasFiles() {
            return this.notification.files && this.notification.files.length > 0;
        },

        statusText() {
            if (this.notification.status === NotificationStatus.InProgress) {
                return "Generating report";
            }
            if (this.hasCompleted) {
                return "Report ready";
            }
            if (this.hasFailed) {
                return "Failed";
            }
            return undefined;
        },

        title() {
            return "Generating report";
        }
    },

    methods: {
        removeNotification() {
            this.$store.dispatch(NotificationsAction.RemoveItem, this.notification.id);
        },

        deleteCompletedNotification() {
            this.$emit("notificationDeleted");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.report-notification-item__list-item {
    list-style-position: inside;
    margin-left: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
