<template>
    <div class="campaign-editor-wrapper">
        <div class="campaign-edit">
            <add-master-template-to-campaign
                v-if="!sandboxMode && !isInitialLoad && !hasTemplates"
                @addTemplate="onAddTemplate"
            ></add-master-template-to-campaign>
            <sandbox-no-templates v-else-if="sandboxMode && !hasTemplates" @retry="onSandboxNoTemplatesRetry" />

            <editor-header
                v-if="hasTemplates"
                :show-add-assets="!sandboxMode"
                :show-add-language="!sandboxMode"
                :show-language-select="!sandboxMode"
                :show-add-templates="!sandboxMode"
                :has-meta-platform="hasMetaPlatform"
                @addTemplate="onAddTemplate"
                @addLanguage="onAddLanguage"
                @saveChanges="onSaveChanges"
                @showImportExportModal="toggleImportExportModalVisible"
                @publishToPlatform="refreshCampaignData"
                @toggleMetaPreview="toggleMetaPreview"
            />

            <div v-if="hasTemplates" ref="body" class="campaign-edit__body dark-scrollbar">
                <missing-font-info />
                <template v-if="sandboxMode || hasVisbleGroupValues">
                    <div class="campaign-edit__deliverables">
                        <deliverable-preview
                            v-for="masterTemplateId in masterTemplateIds"
                            :key="deliverableKey(masterTemplateId)"
                            :master-template="masterTemplates[masterTemplateId]"
                            :max-height="bodyDimensionsPixels.height"
                            :max-width="bodyDimensionsPixels.width"
                            :scaling-factor="scalingFactor"
                            :sandbox-mode="sandboxMode"
                            :is-meta-preview="isMetaPreview"
                            :meta-overwrites="metaOverwrites"
                            @BannerLoaded="onBannerLoaded(masterTemplateId)"
                            @update="onUpdateTemplate"
                            @updateStarted="onUpdateStarted"
                            @familyUpdated="onUpdateTemplate"
                            @remove="onRemoveTemplate"
                            @newSize="showNewSizeModal"
                        />
                    </div>
                </template>
                <empty-message v-else>
                    <template #title>There's nothing to show here</template>
                    <template #content>
                        <p>If needed ask the campaign owner to share the content with you.</p>
                    </template>
                </empty-message>
            </div>

            <div v-if="hasTemplates" class="campaign-edit__banner-controls" :class="{ 'full-width': !isSidebarOpen }">
                <banner-scrubbar :value="bannerPlaybackTime" @change="setBannerPlaybackTime"></banner-scrubbar>
                <campaign-banner-play-all></campaign-banner-play-all>
            </div>

            <div v-if="templateModalVisible" class="campaign-edit-templates-modal">
                <Modal v-model="templateModalVisible" width="95vw" height="95vh" class-name="add-templates-modal">
                    <div class="templates-modal">
                        <div v-if="masterTemplatesToAdd.length" class="templates-modal__sidebar">
                            <master-template-size-filter
                                class="templates-modal__sidebar-filter"
                                :campaign-master-template-ids="masterTemplateIds"
                            ></master-template-size-filter>
                            <master-template-type-filter
                                class="templates-modal__sidebar-filter"
                                :campaign-master-template-ids="masterTemplateIds"
                            ></master-template-type-filter>
                            <master-template-resized-filter
                                class="templates-modal__sidebar-filter"
                                :campaign-master-template-ids="masterTemplateIds"
                            ></master-template-resized-filter>
                            <master-template-family-filter
                                class="templates-modal__sidebar-filter"
                                :campaign-master-template-ids="masterTemplateIds"
                            ></master-template-family-filter>
                        </div>
                        <div class="templates-modal__body">
                            <div class="templates-modal__body-content">
                                <master-template-library
                                    :campaign-master-template-ids="masterTemplateIds"
                                    :in-modal="true"
                                    class="templates-modal__library"
                                    @select="onTemplateSelect"
                                    @add="associateTemplates"
                                ></master-template-library>
                            </div>
                        </div>
                    </div>

                    <template slot="header">
                        <h3>Master Templates</h3>
                    </template>

                    <template slot="footer">
                        <Button type="text" size="large" @click.native="cancelTemplateSelection">Cancel</Button>
                        <Button
                            type="primary"
                            size="large"
                            data-testid="master-templates__button-add"
                            :disabled="!associatedTemplates.length"
                            @click.native="associateTemplates"
                        >
                            Add to campaign
                        </Button>
                    </template>
                </Modal>
            </div>

            <new-size-modal
                v-if="newSizeModalIsVisible"
                :master-template="newSizeSource"
                :has-error="newSizeError"
                @close="closeNewSizeModal"
                @create="onCreateNewSize"
            />

            <campaign-languages-modal
                v-if="languageModalVisible"
                :visible.sync="languageModalVisible"
                @update="onLanguageUpdate"
            />

            <import-export-modal
                v-if="importExportModalVisible"
                :visible.sync="importExportModalVisible"
                :create-new-group-values="createNewGroupValues"
                :language-import-complete="languageImportComplete"
                :language-import-feedback="languageImportFeedback"
                @exportLanguage="onExportLanguage"
                @exportValuesTemplate="onExportValuesTemplate"
                @languagesUpdated="onSimplifiedLanguageUpdate"
                @updateEditable="onImportUpdate"
                @close="toggleImportExportModalVisible"
            />

            <feed-import-modal
                v-if="feedImportModalVisible"
                :visible.sync="feedImportModalVisible"
                :create-new-group-values="createNewGroupValuesAndSaveFeed"
                :group-name="feedImportGroupName"
                :updated-feed-url="updatedFeedUrl"
                :updated-primary-key="updatedPrimaryKey"
                @updateExternalFeedUrl="onFeedImportUpdate"
                @updatePrimaryKey="onUpdatePrimaryKey"
                @updateEditable="onImportUpdate"
                @close="hideFeedImportModal"
                @closeWithSuccess="closeFeedImportModalWithSuccess"
            />

            <sidebar v-if="hasTemplates && hasVisbleGroupValues" :scroll="false" class="campaign-edit-sidebar">
                <div
                    class="sidebar-panes"
                    :class="{
                        'slide-to-left': !isSettingsPaneVisible || showSettingsPaneInModal,
                        'slide-to-right': isSettingsPaneVisible && !showSettingsPaneInModal
                    }"
                >
                    <div class="sidebar-panel sidebar-panel--groups">
                        <div
                            v-bar="{ el1ScrollVisibleClass: 'sidebar-panel--with-scrollbar' }"
                            class="groups-pane sidebar-panel__inner-pane"
                        >
                            <div class="groups-pane__inner">
                                <editable-group-list
                                    class="groups-pane__element"
                                    :sandbox-mode="sandboxMode"
                                    @add="onGroupAdd"
                                    @duplicate="onGroupDuplicate"
                                    @update="onGroupUpdate"
                                    @remove="onGroupRemove"
                                    @edit="onGroupEdit"
                                    @importFeed="showFeedImportModal"
                                    @refreshFeed="onRefreshExternalFeed"
                                    @deleteFeed="onDeleteExternalFeed"
                                    @publishToPlatform="refreshCampaignData"
                                ></editable-group-list>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="isEditablesPaneVisible && !showEditablesPaneInModal"
                        class="sidebar-panel sidebar-panel--editables"
                    >
                        <editables-list-controls
                            v-if="hasSelectedEditables"
                            :all-expanded="allEditablesExpanded"
                            @updateFilter="setNameFilter"
                            @detach="onEditablesDetach"
                            @toggleExpand="toggleExpandEditables"
                        />
                        <labelled-switch
                            v-if="showMetaPreviewToggle"
                            type="info"
                            :value="isMetaPreview"
                            class="preview-social__switch"
                            @change="toggleMetaPreview"
                        >
                            <div>Preview social</div>
                        </labelled-switch>
                        <editables-list
                            class="attached-panel"
                            :sandbox-mode="sandboxMode"
                            :all-expanded="allEditablesExpanded"
                            :name-filter="nameFilter"
                            @detach="onEditablesDetach"
                            @update="onEditableUpdate"
                            @remove="onOverwriteRemove"
                        ></editables-list>
                    </div>
                    <div
                        v-show="isSettingsPaneVisible && !showSettingsPaneInModal"
                        class="sidebar-panel sidebar-panel--settings"
                    >
                        <div class="sidebar-panel__header">
                            <Button
                                v-if="isSettingsPaneVisible && !showSettingsPaneInModal"
                                class="campaign-edit__header-action-btn"
                                size="small"
                                icon="md-swap"
                                @click="showSettingsPaneInModal = !showSettingsPaneInModal"
                            ></Button>
                        </div>

                        <div class="sidebar-panel__inner-pane">
                            <editable-settings-pane @update="onEditableUpdate" />
                        </div>
                    </div>
                </div>
            </sidebar>

            <alert-modal
                v-model="unsavedChangesModal"
                header="You have unsaved changes."
                title="Are you sure?"
                content="Your content edits have not been saved. If you leave this page, your edits will be lost."
            >
                <template #footer>
                    <Button :disabled="isSavingChanges" @click="allowRouteLeaveAndDiscardChanges">Discard</Button>
                    <Button
                        class="campaign-edit__confirm-unsaved-changes-save-button"
                        :loading="isSavingChanges"
                        type="primary"
                        @click="onSaveChanges"
                    >
                        Save edits
                    </Button>
                </template>
            </alert-modal>

            <hox-loading-layer
                v-if="loading"
                :is-opaque="isInitialLoad"
                :left-offset-pixels="60"
                size="large"
                theme="dark"
            />

            <detachable-panel
                class-name="detached-editables"
                :closable="false"
                :value="isEditablesPaneVisible && showEditablesPaneInModal"
                @attach="showEditablesPaneInModal = false"
            >
                <template #title>Content</template>
                <template #content>
                    <editables-list
                        class="dark-scrollbar dark-scrollbar--modal detached-panel"
                        :sandbox-mode="sandboxMode"
                        :scrollable="false"
                        @detach="onEditablesAttach"
                        @update="onEditableUpdate"
                        @remove="onOverwriteRemove"
                    ></editables-list>
                </template>
            </detachable-panel>

            <detachable-panel
                class-name="detached-settings"
                :value="isSettingsPaneVisible && showSettingsPaneInModal"
                :attachable="!showEditablesPaneInModal"
                @attach="showSettingsPaneInModal = false"
                @close="isSettingsPaneVisible = false"
            >
                <template #content>
                    <editable-settings-pane class="dark-scrollbar dark-scrollbar--modal" @update="onEditableUpdate" />
                </template>
            </detachable-panel>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import bus from "@/bus";
import { deepClone } from "@/utils";
import editableUtils from "@/utils/editables";

import Editables from "@/services/Editables";

import { BannerActionEvents } from "@/enums/banners";
import { OverwriteScope, TemplateOverwriteScopes } from "@/enums/overwrites";
import { EditorAction, EditorGetters } from "@/store/modules/editor";

import Sidebar from "@/components/Sidebar";
import AddMasterTemplateToCampaign from "@/components/Campaign/AddMasterTemplateToCampaign";
import EditableGroupList from "@/components/Campaign/EditableGroupList";
import EditablesList from "@/components/Campaign/EditablesList";
import DeliverablePreview from "@/components/Campaign/DeliverablePreview";
import EditorHeader from "@/components/Campaign/EditorHeader";
import BannerScrubbar from "@/components/Previews/BannerScrubbar";
import MasterTemplateSizeFilter from "@/components/Campaign/MasterTemplates/MasterTemplateSizeFilter";
import MasterTemplateLibrary from "@/components/Campaign/MasterTemplateLibrary";
import AlertModal from "@/components/Modal/Alert";
import CampaignLanguagesModal from "@/components/Campaign/CampaignLanguagesModal";
import CampaignBannerPlayAll from "@/components/Campaign/CampaignBannerPlayAll";
import EmptyMessage from "@/components/common/EmptyMessage";
import ImportExportModal from "@/components/Campaign/ImportExportModal";
import NewSizeModal from "@/components/Campaign/NewSizeModal";
import SandboxNoTemplates from "@/components/Sandbox/NoTemplates";

import campaignEditorQuery from "@/apollo/queries/v2/CampaignEditor.gql";
import resizeMasterTemplateMutation from "@/apollo/mutations/ResizeMasterTemplate.gql";
import updateCampaignMutation from "@/apollo/mutations/UpdateCampaign.gql";
import updateCampaignFeeds from "@/apollo/mutations/UpdateCampaignFeeds.gql";
import deleteGroupFeed from "@/apollo/mutations/DeleteGroupFeed.gql";
import addGroupValuesMutation from "@/apollo/mutations/v2/AddEditableGroupValues.gql";
import fontsQuery from "@/apollo/queries/FontsByClient.gql";

import MasterTemplates from "@/services/MasterTemplates";
import { UiAction } from "@/store/modules/ui";
import { MediaLibraryAssetEvent } from "@/components/MediaLibrary";
import bannerTimelineMixin from "@/mixins/bannerTimelineMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import scalingBannerWrapperMixin from "@/mixins/scalingBannerWrapperMixin";
import EditableSettingsPane from "@/views/Campaign/EditableSettingsPane";
import MasterTemplateTypeFilter from "@/components/Campaign/MasterTemplates/MasterTemplateTypeFilter";
import MissingFontInfo from "@/views/Campaign/MissingFontInfo";
import DetachablePanel from "@/views/Campaign/DetachablePanel";
import EditablesListControls from "@/components/Campaign/EditablesListControls";
import ImportExport from "@/services/ImportExport";
import langUtils from "@/utils/languages";
import FeedImportModal from "@/components/Campaign/FeedImportModal";
import { CampaignAction, CampaignGetters } from "@/store/modules/campaign";
import MasterTemplateResizedFilter from "@/components/Campaign/MasterTemplates/MasterTemplateResizeFilter";
import MasterTemplateFamilyFilter from "@/components/Campaign/MasterTemplates/MasterTemplateFamilyFilter";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";

export default {
    name: "CampaignEdit",
    components: {
        MasterTemplateFamilyFilter,
        MasterTemplateResizedFilter,
        FeedImportModal,
        DetachablePanel,
        MissingFontInfo,
        MasterTemplateTypeFilter,
        NewSizeModal,
        EditableSettingsPane,
        ImportExportModal,
        EmptyMessage,
        CampaignBannerPlayAll,
        CampaignLanguagesModal,
        MasterTemplateLibrary,
        MasterTemplateSizeFilter,
        AddMasterTemplateToCampaign,
        BannerScrubbar,
        EditorHeader,
        DeliverablePreview,
        EditablesList,
        EditableGroupList,
        Sidebar,
        AlertModal,
        SandboxNoTemplates,
        EditablesListControls,
        LabelledSwitch
    },

    mixins: [bannerTimelineMixin, editableMethodsMixin, scalingBannerWrapperMixin],

    props: {
        sandboxMode: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            allEditablesExpanded: false,
            associatedTemplates: [],
            bannersUpdateCount: {},
            bannerLoadCounter: 0,
            bannersInitalLoad: 0,
            externalFeedUrl: "",
            isInitialLoad: true,
            isSavingChanges: false,
            loading: true,
            languageModalVisible: false,
            languageImportComplete: false,
            languageImportFeedback: { message: "", languages: [] },
            importExportModalVisible: false,
            feedImportModalVisible: false,
            feedImportGroupName: "",
            max: "460px",
            min: "140px",
            nameFilter: "",
            newSizeModalIsVisible: false,
            newSizeSource: null,
            newSizeError: false,
            primaryKey: "",
            refreshTriggeredAfterSave: false,
            refreshTriggeredByUser: false,
            sandboxInitialBannerLoads: [],
            scrubbarSource: null,
            singlePanelWidth: 250,
            templateModalVisible: false,
            unsavedChangesModal: false,
            unsavedChangesCallback: null,
            updatedFeedUrl: "",
            updatedPrimaryKey: "",
            isFeedImportUpdating: false,
            isMetaPreview: false,
            metaOverwrites: [],
            showMetaPreviewToggle: false
        };
    },

    computed: {
        bannerIframeId() {
            if (this.scrubbarSource) {
                return `iframe-${this.scrubbarSource}`;
            }

            return null;
        },

        canListMasterTemplates() {
            return (
                this.canUseSandbox ||
                this.$auth.userCan(this.$auth.Actions.CanListMasterTemplates, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                })
            );
        },

        canManageOverwrites() {
            return (
                this.canUseSandbox ||
                this.$auth.userCan(this.$auth.Actions.CanManageOverwrites, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                })
            );
        },

        canUseSandbox() {
            return (
                this.sandboxMode &&
                this.$auth.userCan(this.$auth.Actions.CanUseSandbox, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                })
            );
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        campaignLanguages() {
            return this.$store.state.campaign.languages;
        },

        campaignName() {
            return this.$store.state.campaign.name;
        },

        clientId() {
            if (this.sandboxMode) {
                return this.$store.state.campaign.client._id;
            }

            return this.$store.state.route.params.clientId;
        },

        editableGroupValueIds() {
            return this.$store.state.campaign.normalized.editableGroupIds;
        },

        editableGroups() {
            return this.$store.state.campaign.normalized.editableGroups;
        },

        editableIds() {
            return this.$store.state.campaign.normalized.editableIds;
        },

        editableOverwrites() {
            return this.$store.getters[EditorGetters.editableOverwrites];
        },

        hasMetaPlatform() {
            return this.editableOverwrites.some(overwrite => overwrite.editable.publisherPlatform === "facebook");
        },

        editables() {
            return this.$store.state.campaign.normalized.editables;
        },

        graphqlContext() {
            return {
                useLocalDevLink: this.sandboxMode
            };
        },

        hasVisbleGroupValues() {
            const { editableGroupIds, editableGroups, editables } = this.$store.state.campaign.normalized;

            if (
                this.canUseSandbox ||
                this.$auth.userCan(this.$auth.Actions.CanManageRestrictedEditables, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                })
            ) {
                return !!editableGroupIds.length;
            }

            const groupsWithvisibleEditables = editableGroupIds.filter(editableGroupId =>
                editableGroups[editableGroupId].editables.some(editableId => !editables[editableId].restricted)
            );

            return !!groupsWithvisibleEditables.length;
        },

        hasSelectedEditables() {
            return !!this.$store.getters[EditorGetters.selectedEditables].length;
        },

        hasTemplates() {
            return !!this.masterTemplateIds.length;
        },

        isSidebarOpen() {
            return this.$store.state.ui.isSidebarOpen;
        },

        isEditablesPaneVisible: {
            get() {
                return this.$store.state.editor.isEditablesPaneVisible;
            },
            set(isVisible) {
                this.$store.dispatch(EditorAction.SetEditablesPaneVisibility, isVisible);
            }
        },

        isSettingsPaneVisible: {
            get() {
                return this.$store.state.editor.isSettingsPaneVisible;
            },
            set(isVisible) {
                this.$store.dispatch(EditorAction.SetSettingsPaneVisibility, isVisible);
            }
        },

        libraryUrl() {
            return this.$router.resolve({
                name: "CampaignLibrary",
                params: {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                }
            }).href;
        },

        localEditableOverwrites() {
            return this.$store.state.editor.localEditableOverwrites;
        },

        localOverwriteTmpId: {
            get() {
                return this.$store.state.editor.localOverwriteTmpId;
            },
            set(tmpId) {
                this.$store.dispatch(EditorAction.SetLocalOverwriteTmpId, tmpId);
            }
        },

        localOverwritesMade() {
            return (
                this.$store.state.editor.localEditableOverwrites.length > 0 ||
                this.$store.state.editor.removedOverwriteIds.length > 0
            );
        },

        masterTemplateIds() {
            return this.$store.state.campaign.normalized.masterTemplateIds;
        },

        masterTemplates() {
            return this.$store.state.campaign.normalized.masterTemplates;
        },

        masterTemplatesToAdd() {
            return this.$store.state.masterTemplateLibrary.masterTemplateIds.filter(
                templateId => !this.masterTemplateIds.includes(templateId)
            );
        },

        selectedLanguage() {
            return this.$store.state.editor.selectedLanguage;
        },

        selectedGroupValues() {
            return this.$store.state.editor.selectedGroupValues;
        },

        showEditablesPaneInModal: {
            get() {
                return this.$store.state.editor.showEditablesPaneInModal;
            },
            set(useModal) {
                this.$store.dispatch(EditorAction.SetShowEditablesPaneInModal, useModal);
            }
        },

        showSettingsPaneInModal: {
            get() {
                return this.$store.state.editor.showSettingsPaneInModal;
            },
            set(useModal) {
                this.$store.dispatch(EditorAction.SetShowSettingsPaneInModal, useModal);
            }
        }
    },

    watch: {
        bannerState(state) {
            if (!Number.isNaN(state.currentTime)) {
                this.setBannerPlaybackTimeFromBanner(state.currentTime);
            }
        },

        canManageOverwrites: {
            immediate: true,
            handler() {
                if (!this.canManageOverwrites) {
                    this.$snackbar.warning("You do not have access to manage overwrites");
                    this.$router.push({ name: "CampaignLibrary" }).catch(err => {
                        if (err.name !== "NavigationDuplicated") {
                            throw err;
                        }
                    });
                }
            }
        },

        hasTemplates: {
            immediate: true,
            handler(val) {
                this.$store.dispatch(
                    this.hasVisbleGroupValues &&
                        val &&
                        (this.canUseSandbox ||
                            this.$auth.userCan(this.$auth.Actions.CanListGroupValue, {
                                clientId: this.clientId,
                                campaignId: this.campaignId
                            }))
                        ? UiAction.OpenSidebar
                        : UiAction.CloseSidebar
                );

                if (val && this.scrubbarSource) {
                    this.scrubbarSource = null;
                }
            }
        },

        hasSelectedEditables: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.isEditablesPaneVisible = true;
                } else {
                    this.isEditablesPaneVisible = false;
                }
            }
        },

        isEditablesPaneVisible: {
            immediate: true,
            handler(isVisible) {
                if (isVisible) {
                    const multiplier = this.showEditablesPaneInModal ? 1 : 2;
                    this.$store.dispatch(UiAction.SetSidebarWidthOpen, this.singlePanelWidth * multiplier);
                }
            }
        },

        showEditablesPaneInModal: {
            immediate: true,
            handler(showInModal) {
                if (showInModal) {
                    this.showSettingsPaneInModal = true;
                    this.$store.dispatch(UiAction.SetSidebarWidthOpen, this.singlePanelWidth);
                } else {
                    const multiplier = this.isEditablesPaneVisible ? 2 : 1;
                    this.$store.dispatch(UiAction.SetSidebarWidthOpen, this.singlePanelWidth * multiplier);
                }
            }
        },

        async loading(val) {
            if (!val) {
                await this.$nextTick();
                this.setBodyDimensionsPixels();
            }
        },

        selectedGroupValues: {
            immediate: true,
            handler(val) {
                const selectedMetaGroupId = val["facebook Platform"] ? val["facebook Platform"].groupValueId : null;

                this.updateMetaOverwrites(selectedMetaGroupId);
            },
            deep: true
        }
    },

    created() {
        window.addEventListener("resize", this.setBodyDimensionsPixels, true);
        this.loading = true;
        this.importExportService = new ImportExport(this.$apollo, this.$store);
        this.editablesService = new Editables(this.$apollo, this.campaignId, { context: this.graphqlContext });
        this.masterTemplateService = new MasterTemplates(this.$apollo, this.clientId);

        this.unsubscribeLanguageWatcher = this.$store.watch(
            state => state.campaign.languages,
            this.watchLanguage.bind(this)
        );
        bus.$on(MediaLibraryAssetEvent.AssetRemoved, this.watchMediaLibraryAssetRemoved.bind(this));

        bus.$on(BannerActionEvents.AllBannersReplay, this.onUserTriggeredReload.bind(this));
        bus.$on(BannerActionEvents.BannerReplay, this.onUserTriggeredReload.bind(this));

        // Make sure our data is up to date
        this.$apollo.getClient().reFetchObservableQueries();
    },

    beforeDestroy() {
        this.unsubscribeLanguageWatcher();
        this.$store.dispatch(EditorAction.ClearSelectedGroupValues);
    },

    methods: {
        async addEditableGroupValues(editableGroupName, values) {
            return this.$apollo.mutate({
                mutation: addGroupValuesMutation,
                variables: {
                    editableGroupName,
                    values,
                    campaignId: this.campaignId
                },
                context: this.graphqlContext
            });
        },

        async saveExternalFeed(editableGroupName) {
            return this.$apollo.mutate({
                mutation: updateCampaignFeeds,
                variables: {
                    campaignId: this.campaignId,
                    group: editableGroupName,
                    feedUrl: this.externalFeedUrl,
                    primaryKey: this.primaryKey
                }
            });
        },

        allowRouteLeaveAndDiscardChanges() {
            this.unsavedChangesCallback(true);
            this.$store.dispatch(EditorAction.ClearLocalChanges);
        },

        async associateTemplates() {
            this.templateModalVisible = false;
            const campaignTemplatesIds = this.masterTemplateIds;
            const associatedTemplatesIds = this.associatedTemplates;
            const toAssociate = associatedTemplatesIds.filter(tplId => !campaignTemplatesIds.includes(tplId));

            if (toAssociate.length) {
                this.loading = true;
            }

            await Promise.all(
                toAssociate.map(tplId =>
                    this.masterTemplateService.associate(tplId, this.campaignId).catch(err => {
                        const message = `Error associating template ${tplId}.`;
                        if (err.message.includes("Invalid editable names.")) {
                            const explain = err.message
                                .replace(/GraphQL error:/i, "")
                                .replace("Invalid editable names.", "");
                            this.$snackbar.error(`${message} ${explain}`);
                        } else {
                            this.$snackbar.error(`${message}. Please try again.`);
                        }
                    })
                )
            );

            if (toAssociate.length) {
                this.refreshCampaignData();
                this.refreshFontsData();
            }
        },

        cancelTemplateSelection() {
            this.associatedTemplates = [];
            this.templateModalVisible = false;
        },

        async createNewGroupValues(groupName, newGroupValues = []) {
            const {
                data: { addEditableGroupValues: createdGroups }
            } = await this.addEditableGroupValues(groupName, newGroupValues);

            createdGroups.forEach(({ _id, value }) => {
                this.$store.dispatch(CampaignAction.AddEditableGroupValue, {
                    groupName,
                    editableGroupValue: { value, _id }
                });
            });

            return createdGroups;
        },

        async createNewGroupValuesAndSaveFeed(groupName, newGroupValues = []) {
            const createdGroups = await this.createNewGroupValues(groupName, newGroupValues);

            return createdGroups;
        },

        deliverableKey(masterTemplateId) {
            return `${masterTemplateId}-${
                this.bannersUpdateCount[masterTemplateId] ? this.bannersUpdateCount[masterTemplateId] : 0
            }`;
        },

        /* eslint-disable complexity */
        editableUpdate(updateData, overwrite) {
            // TODO simplify this function
            const editableUpdate = deepClone(updateData);
            const affectingGroups = editableUpdate.groupsAffectingEditable;
            const editableGroupValueIds = [];
            // Set mediaItem to null if not found for consistency
            if (typeof editableUpdate.mediaItem === "undefined") {
                editableUpdate.mediaItem = null;
            }
            // Find groups being affected by the editable
            affectingGroups.forEach(groupName => {
                if (groupName in this.selectedGroupValues) {
                    editableGroupValueIds.push(this.selectedGroupValues[groupName].groupValueId);
                }
            });
            // Unknown scope so return an empty object
            if (!Object.values(OverwriteScope).includes(editableUpdate.scope)) {
                return {};
            }
            // Set base properties of overwrite
            const updatedEditable = editableUtils.setScopeValues(editableUpdate.scope, {
                language: this.selectedLanguage,
                scope: editableUpdate.scope,
                editableGroupValueIds,
                masterTemplateId: editableUpdate.masterTemplateId
            });
            // Set tmp overwriteId
            updatedEditable._id = `tmpId-${this.localOverwriteTmpId}`;
            this.localOverwriteTmpId += 1;
            // Set value properties
            if (overwrite.valueFromScope === editableUpdate.scope) {
                updatedEditable.value =
                    editableUpdate.value || editableUpdate.value === "" ? editableUpdate.value : overwrite.value;
                updatedEditable.mediaItem = editableUpdate.mediaItem || overwrite.mediaItem;
            } else {
                updatedEditable.value = editableUpdate.value;
                updatedEditable.mediaItem = editableUpdate.mediaItem;
            }

            // overwrite.value is missing on media settings changes (position, size, etc.). Added the check to set overwrite.value to mediaItem.id
            if (!updatedEditable.value && updatedEditable.mediaItem?.id) {
                updatedEditable.value = updatedEditable.mediaItem.id;
            }

            if (updateData.settings) {
                updatedEditable.settings = editableUpdate.settings;
            } else if (editableUpdate.masterTemplateId) {
                updatedEditable.settings =
                    overwrite.settingsFromScope && overwrite.settingsFromScope[OverwriteScope.EditableGroupTemplate]
                        ? overwrite.settingsFromScope[OverwriteScope.EditableGroupTemplate]
                        : null;
            } else {
                updatedEditable.settings =
                    overwrite.settingsFromScope && overwrite.settingsFromScope[OverwriteScope.EditableGroup]
                        ? overwrite.settingsFromScope[OverwriteScope.EditableGroup]
                        : null;
            }
            // Return updated editable
            return updatedEditable;
        },

        hideFeedImportModal() {
            this.externalFeedUrl = "";
            this.updatedFeedUrl = "";
            this.updatedPrimaryKey = "";
            this.feedImportModalVisible = false;
            this.isFeedImportUpdating = false;
            this.feedImportGroupName = "";
            this.refreshCampaignData();
        },

        async closeFeedImportModalWithSuccess(editableGroupName) {
            await this.saveExternalFeed(editableGroupName);
            this.hideFeedImportModal();
            this.$snackbar.success("Great! Your feed and variables are all set.");
        },

        onAddLanguage() {
            this.languageModalVisible = true;
        },

        onAddTemplate() {
            this.templateModalVisible = true;
        },

        async onBannerLoaded(masterTemplateId) {
            this.refreshTriggeredByUser = false;

            if (!this.scrubbarSource) {
                this.scrubbarSource = masterTemplateId;
            }

            this.onBannerLoadedSandbox();
            this.setBodyDimensionsPixels();
        },

        async onBannerLoadedSandbox() {
            if (!this.sandboxMode || this.refreshTriggeredByUser) {
                return;
            }

            this.bannerLoadCounter += 1;
            // For the banner first load we do not want to trigger the data reftech
            // Only run this function on the last banner load of the set
            if (this.bannersInitialLoad || this.bannerLoadCounter <= this.masterTemplateIds.length) {
                return;
            }

            this.bannersInitialLoad = false;
            this.bannerLoadCounter = 0;

            await this.refreshCampaignData();
        },

        async onCreateNewSize(data) {
            this.newSizeError = false;

            const variables = { ...data, campaignId: this.campaignId };
            try {
                await this.$apollo.mutate({
                    mutation: resizeMasterTemplateMutation,
                    variables,
                    context: this.graphqlContext
                });

                this.closeNewSizeModal();
                this.refreshCampaignData();
                this.$snackbar.success("Master template has been created.");
            } catch (e) {
                this.newSizeError = true;
                this.$snackbar.error("Field to create new master template", e);
            }
        },

        async onImportUpdate(editableUpdate) {
            try {
                await this.importExportService.applyEditableUpdate(editableUpdate);
            } catch (err) {
                this.$snackbar.error(err.message, null, {
                    duration: 0,
                    closable: true
                });
            }
        },

        onFeedImportUpdate(url) {
            this.externalFeedUrl = url;
        },

        onUpdatePrimaryKey(primaryKey) {
            this.primaryKey = primaryKey;
        },

        onRefreshExternalFeed(groupName) {
            this.isFeedImportUpdating = true;
            this.feedImportGroupName = groupName;
            const group = this.$store.state.campaign.groupFeedMappings.find(mapping => mapping.group === groupName);
            this.updatedFeedUrl = group ? group.feedUrl : "";
            this.updatedPrimaryKey = group ? group.primaryKey : "";
            if (!this.updatedFeedUrl || !this.updatedPrimaryKey) {
                this.$snackbar.error("No feed URL found for this group.");
                this.isFeedImportUpdating = false;
            } else {
                this.feedImportModalVisible = true;
                this.$snackbar.info("Feed is being refreshed.", "", {
                    closable: true,
                    duration: 5
                });
            }
        },
        async onDeleteExternalFeed(groupName) {
            try {
                const response = await this.$apollo.mutate({
                    mutation: deleteGroupFeed,
                    variables: {
                        campaignId: this.campaignId,
                        group: groupName
                    }
                });
                this.$snackbar.success("Your linked feed has been successfully removed");
                await this.refreshCampaignData();
                return response;
            } catch (error) {
                this.$snackbar.error(error);
                throw error;
            }
        },

        onEditablesAttach() {
            this.showEditablesPaneInModal = false;
        },

        onEditablesDetach() {
            this.showEditablesPaneInModal = true;
        },

        onEditableUpdate(editableUpdate) {
            const { editable, masterTemplateId, groupsAffectingEditable } = editableUpdate;
            const overwrite = this.getOverwrite(editable, groupsAffectingEditable, masterTemplateId);
            const newOverwrite = this.editableUpdate(editableUpdate, overwrite);

            /*
             * If the overwrite has no settings, value or media iteam it should be removed
             * Otherwise it can be added and the computation will work out which properties over overwrites to put together
             */
            if (this.shouldRemoveOverwrite(editable._id, masterTemplateId, overwrite.scope, newOverwrite)) {
                this.removeOverwrite(overwrite._id);
            } else {
                const updatedEditables = editableUtils.mergeEditables(this.localEditableOverwrites, [
                    {
                        editable,
                        overwrites: [newOverwrite]
                    }
                ]);

                this.$store.dispatch(EditorAction.SetLocalEditableOverwrites, updatedEditables);
            }

            const selectedMetaGroupId = this.selectedGroupValues["facebook Platform"]
                ? this.selectedGroupValues["facebook Platform"].groupValueId
                : null;
            if (selectedMetaGroupId) {
                this.updateMetaOverwrites(selectedMetaGroupId);
            }
        },

        onExportLanguage(language = "en") {
            this.importExportService.exportXLSTranslationsTemplate(language);
        },

        onExportValuesTemplate(type) {
            this.importExportService.exportXLSValuesTemplate(type);
        },

        async onGroupAdd({ groupName, value }) {
            // todo update optimistic updates
            try {
                const newGroupValue = await this.editablesService.addEditableGroupValue(groupName, value);
                const newGroupValueId = newGroupValue.data.addEditableGroupValue._id;

                this.$store.dispatch(CampaignAction.AddEditableGroupValue, {
                    groupName,
                    editableGroupValue: {
                        value,
                        _id: newGroupValueId
                    }
                });

                return newGroupValueId;
            } catch (e) {
                this.$snackbar.error("We could not add your group. Try again.");
                return null;
            }
        },

        async onGroupDuplicate({ groupName, groupValueId }) {
            const { editableGroupValuesNames, editables } =
                this.$store.getters[CampaignGetters.groupValueNamesByGroup][groupName];

            const groupValue = this.$store.state.campaign.editableGroupValues[groupValueId];

            let i = 1;
            let newName = `${groupValue.value} (${i})`;
            while (editableGroupValuesNames.includes(newName)) {
                i += 1;
                newName = `${groupValue.value} (${i})`;
            }

            const newGroupValueId = await this.onGroupAdd({ groupName, value: newName });
            if (!newGroupValueId) {
                this.$snackbar.error("Group value duplication failed.");
                return;
            }

            editables.forEach(editableId => {
                this.editableOverwrites.forEach(({ editable, overwrites }) => {
                    if (editable._id === editableId) {
                        const overwritesToDuplicate = overwrites.filter(({ editableGroupValueIds }) =>
                            editableGroupValueIds.includes(groupValueId)
                        );

                        if (overwritesToDuplicate.length) {
                            this.addLocalOverwrite(editable, overwritesToDuplicate, groupValueId, newGroupValueId);
                        }
                    }
                }, []);
            });
            this.$snackbar.success("Group value was duplicated successfully");
        },

        async addLocalOverwrite(editable, overwrites, groupValueId, newGroupValueId) {
            const updatedEditable = {
                editable,
                overwrites: overwrites.map(overwrite => {
                    const _id = `tmpId-${this.localOverwriteTmpId}`;
                    this.localOverwriteTmpId += 1;
                    const matchingIndex = overwrite.editableGroupValueIds.indexOf(groupValueId);
                    const editableGroupValueIds = [...overwrite.editableGroupValueIds];
                    editableGroupValueIds.splice(matchingIndex, 1, newGroupValueId);

                    // mediaItem overwrites needs to have it's id set as a value when we are saving overwrite
                    return {
                        ...overwrite,
                        _id,
                        editableGroupValueIds,
                        ...(overwrite.mediaItem && !overwrite.value && { value: overwrite.mediaItem.id })
                    };
                })
            };

            const updatedEditables = editableUtils.mergeEditables(this.$store.state.editor.localEditableOverwrites, [
                updatedEditable
            ]);

            await this.$store.dispatch(EditorAction.SetLocalEditableOverwrites, updatedEditables);
        },

        onGroupUpdate(data) {
            this.$store.dispatch(EditorAction.SelectGroupValue, data);
            if (data.groupName === "facebook Platform") {
                this.showMetaPreviewToggle = true;
            } else {
                this.showMetaPreviewToggle = false;
            }
        },

        async onGroupEdit(data) {
            const previousEditableGroupValue =
                this.$store.state.campaign.editableGroupValues[data.editableGroupValues[0].editableGroupValueId];
            const restoreData = {
                ...data,
                editableGroupValues: previousEditableGroupValue
            };

            this.$store.dispatch(CampaignAction.UpdateEditableGroupValue, {
                groupName: data.groupName,
                editableGroupValue: {
                    _id: data.editableGroupValues[0].editableGroupValueId,
                    value: data.editableGroupValues[0].value
                }
            });

            try {
                await this.editablesService.updateEditableGroupValues(data.editableGroupValues);
            } catch (e) {
                this.$snackbar.error("We could not update your variation. Try again.");
                this.$store.dispatch(CampaignAction.UpdateEditableGroupValue, {
                    groupName: restoreData.groupName,
                    editableGroupValue: restoreData.editableGroupValues
                });
            }
        },

        async onGroupRemove(data) {
            const previousEditableGroupValue =
                this.$store.state.campaign.editableGroupValues[data.editableGroupValueIds[0]];
            const restoreData = {
                ...data,
                editableGroupValue: previousEditableGroupValue
            };
            data.editableGroupValueIds.forEach(editableGroupValueId => {
                this.$store.dispatch(CampaignAction.RemoveEditableGroupValue, {
                    editableGroupValueId,
                    groupName: data.groupName
                });
            });

            try {
                await this.editablesService.removeEditableGroupValues(data.editableGroupValueIds);
                data.editableGroupValueIds.forEach(editableGroupValueId => {
                    this.$store.dispatch(EditorAction.ClearGroupValueData, editableGroupValueId);
                });
            } catch (e) {
                this.$snackbar.error("We could not remove your group value", e.message);
                this.$store.dispatch(CampaignAction.AddEditableGroupValue, {
                    groupName: restoreData.groupName,
                    editableGroupValue: {
                        value: restoreData.editableGroupValue.value,
                        _id: restoreData.editableGroupValue._id
                    }
                });
            }
        },

        async onLanguageUpdate(languages) {
            // todo: refactor into service when we get rid of the old campaigns code.
            try {
                this.$snackbar.info("Updating Languages...");
                await this.$apollo.mutate({
                    mutation: updateCampaignMutation,
                    variables: {
                        campaignId: this.campaignId,
                        campaign: {
                            languages
                        }
                    }
                });

                await this.refreshCampaignData();
                this.$snackbar.success("Languages updated.");
            } catch (e) {
                this.$snackbar.error("We could not update languages. Try again..");
                this.$snackbar.graphQLError(e);
            } finally {
                bus.$emit("percentLoadEvent", 100);
            }
        },

        async onSandboxNoTemplatesRetry() {
            await this.refreshCampaignData();
        },

        async onSimplifiedLanguageUpdate({ languageData }) {
            this.languageImportComplete = false;
            // need to add this as the code changes too quickly and the modal couldn't set the correct ui state
            await Vue.nextTick();

            this.languageImportFeedback = { message: "", languages: languageData.languages };

            const phrasesCount = languageData.languages.reduce((acc, language) => {
                this.importExportService.simplifiedLanguageUpdate({
                    language,
                    languageMapping: languageData[language]
                });
                return acc + Object.keys(languageData[language]).length;
            }, 0);

            const langCountText = (count, showCount = true) => {
                const form = count === 1 ? "language" : "languages";

                if (showCount) {
                    return `${count} ${form}`;
                }

                return form;
            };

            this.languageImportFeedback.message = `${phrasesCount} phrases in ${langCountText(
                languageData.languages.length
            )} have been applied in the editor.`;
            const languagesToAdd = languageData.languages.filter(lang => !this.campaignLanguages.includes(lang));
            if (languagesToAdd.length) {
                this.onLanguageUpdate(languagesToAdd.concat(this.campaignLanguages));
                const langsText = languagesToAdd.map(l => langUtils.localeToFriendlyName(l));
                this.languageImportFeedback.newLanguages = `${languagesToAdd.length} new ${langCountText(
                    languagesToAdd.length,
                    false
                )} (${langsText}) have been added to the campaign`;
            }

            this.languageImportComplete = true;
        },

        onOverwriteRemove(editableOverwriteToRemove) {
            const updatedEditables = this.localEditableOverwrites.map(editableOverwrite => {
                if (editableOverwrite.editable._id === editableOverwriteToRemove.editable._id) {
                    const toUpdate = deepClone(editableOverwrite);
                    const tplScopeFilter = overwrite =>
                        !(
                            overwrite.masterTemplateId === editableOverwriteToRemove.masterTemplateId &&
                            overwrite.language === editableOverwriteToRemove.language &&
                            overwrite.scope === editableOverwriteToRemove.scope
                        );

                    const grpScopeFilter = overwrite =>
                        !(
                            overwrite.language === editableOverwriteToRemove.language &&
                            overwrite.scope === editableOverwriteToRemove.scope
                        );

                    const filterFn = TemplateOverwriteScopes.includes(editableOverwriteToRemove.scope)
                        ? tplScopeFilter
                        : grpScopeFilter;
                    toUpdate.overwrites = toUpdate.overwrites.filter(filterFn);

                    return toUpdate.overwrites.length ? toUpdate : null;
                }

                return editableOverwrite;
            });

            this.$store.dispatch(
                EditorAction.SetLocalEditableOverwrites,
                updatedEditables.filter(up => !!up)
            );
        },

        onPlay() {
            bus.$emit(BannerActionEvents.AllBannersPlay);
        },

        onPause() {
            bus.$emit(BannerActionEvents.AllBannersPause);
        },

        showNewSizeModal(masterTemplate) {
            this.newSizeSource = masterTemplate;
            this.newSizeModalIsVisible = true;
        },

        async onRemoveTemplate(masterTemplateId) {
            try {
                await this.masterTemplateService.disassociate(masterTemplateId, this.campaignId);
                this.$store.dispatch(CampaignAction.DisassociateMasterTemplate, masterTemplateId);
                this.loading = true;
                this.refreshCampaignData();
                this.refreshFontsData();
            } catch (e) {
                this.$snackbar.error("Master Template could not be removed");
                this.loading = false;
            }
        },

        async onSaveChanges() {
            if (!this.isSavingChanges) {
                this.isSavingChanges = true;
                try {
                    this.$snackbar.info("Saving local changes...");

                    if (this.$store.state.editor.removedOverwriteIds.length) {
                        await this.editablesService.removeOverwrites(this.$store.state.editor.removedOverwriteIds);
                    }

                    if (this.$store.state.editor.localEditableOverwrites.length) {
                        await this.editablesService.addOverwrites(this.$store.state.editor.localEditableOverwrites);
                    }

                    this.refreshTriggeredAfterSave = true;
                    await this.refreshCampaignData();
                    this.$snackbar.success(
                        "Changes saved",
                        !this.sandboxMode
                            ? `All changes have been saved. Head to your <a href="${this.libraryUrl}">Creative Library</a> to preview your ads.`
                            : undefined
                    );
                    // If save was called from the unsaved modal then fire callback
                    if (this.unsavedChangesCallback) {
                        this.unsavedChangesCallback(true);
                        this.unsavedChangesCallback = null;
                    }
                } catch (e) {
                    this.$snackbar.graphQLError(e);
                }
                this.isSavingChanges = false;
            }
        },

        async onUpdateStarted() {
            this.loading = true;
        },

        async onUpdateTemplate(masterTemplateIds) {
            this.loading = true;
            await this.refreshCampaignData();
            this.refreshFontsData();
            const templatesToReload = Array.isArray(masterTemplateIds) ? masterTemplateIds : [masterTemplateIds];
            templatesToReload.forEach(masterTemplateId => {
                const currentCount =
                    masterTemplateId in this.bannersUpdateCount ? this.bannersUpdateCount[masterTemplateId] : 0;
                Vue.set(this.bannersUpdateCount, masterTemplateId, currentCount + 1);
            });
        },

        onUserTriggeredReload() {
            this.refreshTriggeredByUser = true;
        },

        onTemplateSelect(selectedTemplates) {
            this.associatedTemplates = selectedTemplates;
        },

        async refreshCampaignData() {
            if (this.$apollo.queries.editableGroupsByCampaign) {
                await this.$apollo.queries.editableGroupsByCampaign.refetch();
            }
        },

        async refreshFontsData() {
            if (this.$apollo.queries.fonts) {
                await this.$apollo.queries.fonts.refetch();
            }
        },

        showFeedImportModal(groupName) {
            this.feedImportGroupName = groupName;
            this.feedImportModalVisible = true;
        },

        toggleImportExportModalVisible() {
            this.importExportModalVisible = !this.importExportModalVisible;
        },

        toggleExpandEditables() {
            this.allEditablesExpanded = !this.allEditablesExpanded;
        },

        closeNewSizeModal() {
            this.newSizeModalIsVisible = false;
            this.newSizeSource = null;
        },

        watchLanguage(languages, oldLanguages) {
            oldLanguages.forEach(language => {
                if (!languages.includes(language)) {
                    this.$store.dispatch(EditorAction.ClearLanguageData, language);
                }
            });
        },

        watchMediaLibraryAssetRemoved(asset) {
            // remove local overwrites
            // todo: add support for recursive delete of files when parent dir is removed
            this.$store.dispatch(EditorAction.ClearLocalAssetChanges, asset);

            // make sure that the stored overwrites are updated.
            this.refreshCampaignData();
        },

        setNameFilter(filter) {
            this.nameFilter = filter;
        },

        toggleMetaPreview() {
            this.isMetaPreview = !this.isMetaPreview;
        },

        updateMetaOverwrites(selectedMetaGroupId) {
            const results = {};
            this.editableOverwrites.forEach(item => {
                if (item.overwrites && item.overwrites.length > 0 && selectedMetaGroupId) {
                    item.overwrites.forEach(overwrite => {
                        if (
                            overwrite.editableGroupValueIds &&
                            overwrite.editableGroupValueIds.includes(selectedMetaGroupId)
                        ) {
                            if (typeof overwrite.value === "string") {
                                results[item.editable.name] = overwrite.value;
                            } else if (Array.isArray(overwrite.value)) {
                                const selectedValue = overwrite.value.find(value => value.selected === true);
                                if (selectedValue) {
                                    results[item.editable.name] = selectedValue.label;
                                }
                            }
                        }
                    });
                }
            });

            this.metaOverwrites = results;
        }
    },

    beforeRouteLeave(to, from, next) {
        if (this.localOverwritesMade) {
            this.unsavedChangesModal = true;
            this.unsavedChangesCallback = goToNext => {
                if (goToNext) {
                    next();
                } else {
                    next(false);
                }
            };
        } else {
            next();
        }
    },

    apollo: {
        editableGroupsByCampaign: {
            query: campaignEditorQuery,
            fetchPolicy: "no-cache",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            skip() {
                return !this.canManageOverwrites;
            },

            /*
            Setting the context here allows us to tell the link chain whether or not queries should be redirected
            Not documented well in the Vue Apollo docs but they mention it here:
            https://apollo.vuejs.org/api/smart-query.html#options - You can also use any other watchQuery options
            Unfortunately the link they reference is broken. The resource it should point to is here:
            https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.watchQuery
            */
            context() {
                return this.graphqlContext;
            },

            async result({ data }) {
                const imageDimensionsByDefaultValue =
                    await editableUtils.getImageDimensionsByDefaultValueFromEditablesWithOverwrites(
                        data.editablesWithOverwritesByCampaign
                    );
                if (this.$store.state.campaign._id !== data.campaign._id) {
                    this.$store.dispatch(EditorAction.ResetEditor);
                    this.$store.dispatch(CampaignAction.SetInitialState);
                }
                this.$store.dispatch(CampaignAction.SetCampaign, data.campaign);
                const editablesWithOverwritesByCampaignWithDefaultImageDimensions =
                    editableUtils.getEditablesWithOverwritesByCampaignWithDefaultImageDimensions(
                        data.editablesWithOverwritesByCampaign,
                        imageDimensionsByDefaultValue
                    );
                this.$store.dispatch(
                    CampaignAction.SetEditableOverwrites,
                    editablesWithOverwritesByCampaignWithDefaultImageDimensions
                );
                this.$store.dispatch(CampaignAction.SetMasterTemplates, data.masterTemplatesByCampaign);
                const editableGroupsByCampaignWithDefaultImageDimensions =
                    editableUtils.getEditableGroupsByCampaignWithDefaultImageDimensions(
                        data.editableGroupsByCampaign,
                        imageDimensionsByDefaultValue
                    );
                this.$store.dispatch(CampaignAction.SetGroups, editableGroupsByCampaignWithDefaultImageDimensions);
                if (this.refreshTriggeredAfterSave) {
                    /*
                        Because the query result callback (this function) is now
                        async, we need to wait until it is finished because triggering
                        to clear local changes.

                        Previously we were clearing local changes directly
                        after the query refresh call, which was before the result
                        callback had time to finish, resulting in getting old
                        values (images/text) displayed in the banners.
                    */
                    this.refreshTriggeredAfterSave = false;
                    this.$store.dispatch(EditorAction.ClearLocalChanges);
                }

                if (!this.sandboxMode && !this.hasVisbleGroupValues && !this.canListMasterTemplates) {
                    this.$snackbar.warning(
                        "There's no content for you to edit. If you were expecting to see something here then please contact the campaign owner.",
                        null,
                        {
                            closable: true,
                            duration: 10
                        }
                    );
                    this.$router.push({ name: "CampaignLibrary" }).catch(err => {
                        if (err.name !== "NavigationDuplicated") {
                            throw err;
                        }
                    });
                }

                this.loading = false;
                this.isInitialLoad = false;
            }
        },
        fonts: {
            query: fontsQuery,
            fetchPolicy: "no-cache",
            variables() {
                return {
                    clientId: this.clientId
                };
            },

            skip() {
                return !this.canManageOverwrites || this.sandboxMode;
            },

            async result({ data }) {
                this.$store.dispatch(EditorAction.SetFonts, data.fonts);
            }
        }
    }
};
</script>

<style lang="scss">
@use "sass:color";
@import "../../../sass/variables";

.campaign-editor-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.campaign-edit {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &__body {
        flex: 1;
        overflow-y: auto;
        position: relative;
        padding: 22px 20px;
    }

    &__confirm-unsaved-changes-save-button {
        background: $blue;
        border: 1px solid $blue;

        &:focus {
            background: $blue;
            border: 1px solid $blue;
        }

        &:hover {
            background: color.adjust($blue, $lightness: 10%);
            border: 1px solid color.adjust($blue, $lightness: 10%);
        }

        &:active {
            background: $blue;
            border: 1px solid $blue;
        }
    }

    &__header {
        padding: 0 20px;
        overflow-x: auto;
    }

    &__banner-controls {
        flex: 0 0 60px;
        height: 60px;
        padding: 0 10px;
        width: 100%;
        background-color: $cmp-dark-bg-color;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .timeline-slider {
            width: 100%;
            display: flex;
            flex: 1 0;
            align-items: center;
        }
    }

    .feed-import-modal--update {
        opacity: 0.01;
    }
}

.add-templates-modal {
    .ivu-modal {
        top: 3vh;
    }

    .ivu-modal-content {
        height: 100%;
    }

    .templates-modal {
        display: flex;
        flex: 1;

        &__sidebar {
            display: flex;
            flex: 0 0 215px;
            width: 215px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background-color: $white;

            &-filter {
                width: 100%;
                flex: 0 0;
            }

            .ivu-collapse {
                background: $white;
            }

            .ivu-collapse > .ivu-collapse-item {
                .ivu-collapse-header {
                    border: none;
                    background: $white;
                }

                &.ivu-collapse-item-active {
                    border-bottom: 1px solid $cmp-light-filters-border-color;

                    .ivu-collapse-header {
                        background: $cmp-light-filters-border-color;
                    }
                }
            }
        }

        &__body {
            flex: 1;
            padding: 0 20px;
        }

        &__library {
            height: 74vh;
            display: flex;
            flex-direction: column;
        }
    }
}

.sidebar-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
}

.sidebar-panes {
    display: flex;
    justify-content: flex-start;
    position: fixed;
    left: 60px;
    top: 0;
    height: 100vh;
    width: 750px;
}

.sidebar-panel {
    flex: 0 0 250px;
    height: 100vh;
    width: 250px;
    border-right: $cmp-dark-border-width solid $cmp-dark-border-color;

    &__header {
        height: $campaign-nav-height;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 8px;
    }

    &__inner-pane {
        border-top: $cmp-dark-border-width solid $cmp-dark-border-color;
        margin-top: $campaign-nav-height;
    }

    &--settings {
        .sidebar-panel__inner-pane {
            margin-top: 0;
        }
    }

    &--groups {
        .groups-pane {
            height: calc(100vh - 80px);
        }
    }

    &--with-scrollbar {
        .groups-pane__element {
            width: calc(100% - 15px);
            padding-bottom: 50px;
        }
    }

    .preview-social__switch {
        padding: 10px;
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        align-items: baseline;
        align-items: center;
        justify-content: flex-end;
    }
}

.slide-to-left {
    transform: translateX(0px);
    transition: all 150ms linear 0s;
}

.slide-to-right {
    transform: translateX(-250px);
    transition: all 150ms linear 0s;
}

.campaign-edit-sidebar {
    overflow: hidden;
    position: relative;
}

.detached-editables .ivu-modal-content {
    top: 40px;
    left: calc(100% - 270px);
}

.detached-settings .ivu-modal-content {
    top: 80px;
    left: calc(100% - 260px);
}
</style>
