// eslint-disable-next-line import/no-extraneous-dependencies
import { UserActions as Actions, UserActionsToAuthorizeByScope } from "shared-utils/enums/user";
// TODO update this to use @apollo/client/link/context
import { setContext } from "apollo-link-context";
import { Amplify } from "aws-amplify";
import {
    fetchAuthSession,
    signIn,
    signOut,
    signInWithRedirect,
    resetPassword,
    confirmResetPassword,
    confirmSignIn
} from "aws-amplify/auth";
import store from "@/store";
import { AuthGetters } from "@/store/modules/auth";
import { DefaultUser } from "@/enums/users";

const getAuthConfig = async () => {
    const response = await fetch(`${window.location.origin}/oauth-config`);

    if (response.status !== 200) {
        const error = await response.json();
        throw new Error(error.message || "Error retrieving Auth Config");
    }

    return response.json();
};

const setAuthConfig = async authConfig => {
    // Set config on Amplify
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: authConfig.userPoolId,
                userPoolClientId: authConfig.userPoolWebClientId,
                loginWith: {
                    oauth: {
                        domain: authConfig.ssoURL.replace(/^https?:\/\//, ""),
                        redirectSignIn: [`https://${document.subdomain}/`],
                        redirectSignOut: [`https://${document.subdomain}/`],
                        scopes: ["openid"],
                        responseType: "code"
                    }
                }
            }
        }
    });
};

const logout = async () => {
    // Returns true to confirm the user was logged out
    await signOut();
    return true;
};

const login = async (username, password) => signIn({ username, password });

const providerLogin = async provider => {
    await signInWithRedirect({
        provider: {
            custom: provider
        }
    });
};

const getName = (user, firstNameOnly) => {
    if (firstNameOnly) {
        return user.given_name;
    }
    if (user.given_name && user.family_name) {
        return `${user.given_name} ${user.family_name}`;
    }
    if (user.email) {
        return user.email;
    }
    if (user["cognito:username"]) {
        return user["cognito:username"];
    }
    return null;
};

const userDisplayName = async (firstNameOnly = false) => {
    // Get current oauth session and user
    const { tokens } = await fetchAuthSession();

    if (!tokens?.idToken) {
        return DefaultUser;
    }

    // Return default display name if user name not found
    return getName(tokens.idToken.payload, firstNameOnly) || DefaultUser;
};

const userEmail = async () => {
    // Get current oauth session and user
    const { tokens } = await fetchAuthSession();

    if (!tokens?.idToken) {
        return "";
    }

    // Return default display name if user name not found
    return tokens.idToken.payload.email;
};

const getPayload = async () => {
    const { tokens } = await fetchAuthSession();

    if (!tokens?.idToken) {
        return {};
    }

    return tokens.idToken.payload;
};

const getIdToken = async () => {
    const { tokens } = await fetchAuthSession();

    if (!tokens?.idToken) {
        return null;
    }

    return tokens.idToken.toString();
};

const isAuthorized = async authConfig => {
    setAuthConfig(authConfig);

    return !!(await getIdToken());
};

const forgotPassword = async username => resetPassword({ username });

const forgotPasswordSubmit = async (username, code, password) =>
    confirmResetPassword({ username, confirmationCode: code, newPassword: password });

const completeNewPassword = async password => confirmSignIn({ challengeResponse: password });

const hasScope = (scope, exact = true) => store.getters[AuthGetters.hasScope](scope, exact);

// eslint-disable-next-line complexity
const userCan = (action, scope = null, exact = true) => {
    const isScopeRequired = UserActionsToAuthorizeByScope.includes(action);

    if (isScopeRequired && !scope) {
        throw new Error(`AuthService: Scope required for action: ${action}`);
    }

    return isScopeRequired
        ? store.getters[AuthGetters.userCan](action) && scope && hasScope(scope, exact)
        : store.getters[AuthGetters.userCan](action);
};

export const asyncAuthLink = setContext(async () => ({
    // Add ID Token as auth header for API calls
    headers: { Authorization: await getIdToken() }
}));

export default {
    login,
    logout,
    providerLogin,
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
    userDisplayName,
    userEmail,
    getAuthConfig,
    isAuthorized,
    getPayload,
    userCan,
    hasScope,
    Actions
};
