<template>
    <hox-notification-item
        :has-content="hasContent"
        :progress-percentage="progressPercentage"
        :show-clear-button="hasFailed || hasCompleted"
        :show-error-message="hasFailed"
        :show-abort-button="showAbortButton"
        :status="notification.status"
        :notification="notification"
        :is-job-finished="isJobFinished"
        @clear="removeNotification"
        @notificationDeleted="deleteCompletedNotification"
    >
        <template #title>
            <span v-if="notification.job.campaignDetails && notification.job.clientDetails">
                Client:
                <strong>{{ notification.job.clientDetails?.name }}</strong>
                <br />
                Campaign:
                <strong>{{ notification.job.campaignDetails?.name }}</strong>
            </span>
            {{ title }}
        </template>
        <template #subtitle>
            {{ statusText }}
        </template>
        <template #content>
            <template v-if="hasContent">
                <p><strong>These feeds have been updated:</strong></p>
                <ul>
                    <li
                        v-for="xmlFeedUrl in notification.job.payload.xmlFeedUrls || []"
                        :key="xmlFeedUrl"
                        class="publish-notification-item__list-item"
                        :title="xmlFeedUrl"
                    >
                        <a :href="xmlFeedUrl" target="_blank">
                            {{ xmlFeedUrl }}
                        </a>
                    </li>
                </ul>
            </template>
        </template>
        <template #errorMessage>
            <p>{{ notification.job.message }}</p>
            <p>Please try again in a few moments.</p>
        </template>
    </hox-notification-item>
</template>

<script>
import { NotificationStatus, NotificationTypes } from "@/enums/notifications";
import { PlatformLabel } from "@/enums/platforms";
import { NotificationsAction } from "@/store/modules/notifications";

export default {
    props: {
        notification: {
            required: true,
            type: Object
        },

        showAbortButton: {
            type: Boolean
        },

        isJobFinished: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        adOrAds() {
            return this.numberOfAds === 1 ? "ad" : "ads";
        },

        numberOfAds() {
            // get if from deliverablesCount (if provided), or from the job payload (if provided)
            return this.notification.deliverablesCount || this.notification.job?.payload?.publishingAdsCount || "Many";
        },

        completedStatusText() {
            if (this.isPublishing) {
                return `${this.numberOfAds} ${this.adOrAds} added to ${this.platformLabel} feeds`;
            }
            return `${this.numberOfAds} ${this.adOrAds} removed from ${this.platformLabel} feeds`;
        },

        hasCompleted() {
            return this.notification.status === NotificationStatus.Completed;
        },

        hasFailed() {
            return this.notification.status === NotificationStatus.Failed;
        },

        isPublishing() {
            return this.notification.type === NotificationTypes.Publish;
        },

        platformLabel() {
            return PlatformLabel[this.notification.platform || this.notification.job?.payload?.platform] || "unknown";
        },

        publishingOrUnpublishing() {
            return this.isPublishing ? "Publishing" : "Unpublishing";
        },

        statusText() {
            if (this.notification.status === NotificationStatus.InProgress) {
                return `${this.publishingOrUnpublishing} ${this.numberOfAds} ${this.adOrAds}`;
            }
            if (this.hasCompleted) {
                return this.completedStatusText;
            }
            if (this.hasFailed) {
                return "Failed";
            }
            return undefined;
        },

        title() {
            if (this.isPublishing) {
                return `Publish to ${this.platformLabel} feeds`;
            }
            return `Unpublish from ${this.platformLabel} feeds`;
        },

        hasContent() {
            return (this.notification.job?.payload?.xmlFeedUrls || []).length > 0;
        },

        progressPercentage() {
            return this.notification.job?.progress;
        }
    },

    methods: {
        removeNotification() {
            this.$store.dispatch(NotificationsAction.RemoveItem, this.notification.id);
        },

        deleteCompletedNotification() {
            this.$emit("notificationDeleted");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.publish-notification-item__list-item {
    list-style-position: inside;
    margin-left: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
